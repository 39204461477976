import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import '../../assets/css/evento.css';

import ima from '../../assets/images/temp/50x50.png';
import logo from '../../assets/images/2024/Logos_Botones_Iconos/casaduana_LogoHeader.svg';
import ima2 from '../../assets/images/2024/Logos_Botones_Iconos/casaduana_ApartaTuLugar.svg';
import { Auth } from '../../utils/AuthContext';
import Login from '../registro/Login';


const secciones = [
  { nombre: 'Acerca de', url: '/#acerca-de', nombre_en: 'ABOUT US' },
  { nombre: 'Beneficios', url: '/#beneficios', nombre_en: 'ABOUT US' },
  { nombre: 'Programa', url: '/#programa', nombre_en: 'SCHEDULE' },
  { nombre: 'Speakers', url: '/#speakers', nombre_en: '' },
  { nombre: 'Organizadores', url: '/#organizadores' },
  //{ nombre: 'HOSPEDAJE', url: '/#hospedaje' },
  //{ nombre: 'MEMBRESÍA', url: '/#membresia', nombre_en: 'MEMBERSHIP' },

  //{ nombre: 'CONFERENCISTAS', url: '/#conferencistas', nombre_en: '' },
  //{ nombre: 'GRUPO ANFITRIÓN', url: '/grupo-anfitrion', nombre_en: '' },
  //{ nombre: 'REGISTRO', url: '/registro', nombre_en: 'REGISTER' },
  //{nombre:'TRANSMISIÓN', url:'/transmision'},
];

const success = () => {
  mensaje('success', 'Bienvenido', '');
};


const mensaje = (tipo, titulo, texto) => {

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}

const Header = (props) => {

  const { usuario } = useContext(Auth);
  const [modal_entrar, setModalEntrar] = useState(false);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_submenu, setMostrarSubmenu] = useState(false);
  const { setIdioma, idioma } = props;

  const [scroll, setScroll] = useState(0);
  const [ver_color, setVerColor] = useState(true);
  const [ver_lugar, setVerLugar] = useState(false);

  useEffect(() => {
    if (usuario) {
      setMostrarLogin(false);
    } else {

      setMostrarLogin(false);//cambiar a true
    }
  }, [usuario]);



  let location = useLocation();

  const [redireccionar_en_vivo, setRedireccionarEnVivo] = useState(false);


  useEffect(() => {
    if (redireccionar_en_vivo) {
      window.location.href = "/transmision";
    }
  }, [redireccionar_en_vivo]);

  useEffect(() => {
    if (location.pathname === '' || location.pathname === '/' || location.pathname === '/registro' || location.pathname === '/costos' || location.pathname === '/transmision') {
      setVerLugar(false)
    } else {
      setVerLugar(true)
    }

  }, [location]);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
      setVerColor(scrollCheck);
    })
  }, [])

  let visualizar_secciones = secciones.map((seccion) => {
    return (
      <Link className="titles" to={seccion.url}>
        <span className={location.pathname + '' + location.hash === seccion.url || location.pathname === seccion.url ? "seccion_sel " : "seccion "} >
          {seccion.nombre}
        </span>
      </Link>
    );
  });
  

  return (
    <>
      <Row justify="" align="middle" className={ver_lugar === true ? (ver_color === true ? 'back_menutop_membresia' : 'back_menu_scroll') : (ver_color === true ? 'back_menu' : 'back_menu_scroll')} style={{ paddingTop: '10px', paddingBottom: '10px', position: 'fixed', zIndex: '99', width: '100%' }}>
        <Col xs={{ span: 7, offset: 0 }} md={{ span: 4, offset: 2 }} style={{ padding: '0px' }}>

          <img alt="logo" style={{ width: '60%' }} src={logo} />


        </Col>
        <Col xs={{ span: 0, offset: 0 }} lg={{ span: 16, offset: 0 }} style={{ textAlign: 'right' }}>
          {visualizar_secciones}
          <Link className="" to="/#registro" style={{ cursor:'pointer' }}>
            <img src={ima2} style={{width:'130px'}} />
          </Link>

        </Col>

        <Col xs={{ span: 6, offset: 9 }} lg={{ span: 0, offset: 0 }} style={{ textAlign: 'right' }}>

          <MenuOutlined onClick={() => { setMostrarSubmenu(!mostrar_submenu) }} style={{ padding: '0px', fontSize: '20px', color: '#263259' }} />

        </Col>
        {mostrar_submenu &&
          <Col xs={{ span: 24 }} lg={{ span: 0 }} style={{ display: 'non', background: '#' }}>

            <>
              <p >
                <Link to="/#programa" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                  Acerca de
                </Link>
              </p>

              <p style={{ display: 'non' }}>
                <Link to="/#conferencistas" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                  Beneficios
                </Link>
              </p>
              <p style={{ display: 'non' }}>
                <Link to="/#hospedaje" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                  Programa
                </Link>
              </p>
              <p style={{ display: 'non' }}>
                <Link to="/costos" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                  Speakers
                </Link>
              </p>
              <p style={{ display: 'non' }}>
                <Link to="/#patrocinadores" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                  Organizadores
                </Link>
              </p>

              <p style={{ display: 'non' }}>
                <Link to="/#registro" onClick={() => { setMostrarSubmenu(false) }} className="seccion">
                  REGISTRO
                </Link>
              </p>
            </>





          </Col>}
          
    </Row>
      <Modal
        title="INICIA SESIÓN"
        open={mostrar_login}
        onOk={() => { setMostrarLogin(false) }}
        onCancel={() => { setMostrarLogin(false) }}
        footer={null}
      >
        <Login onResponse={() => { setMostrarLogin(false); setRedireccionarEnVivo(true); }} onClick={success} />
      </Modal>

    </>
  );
}

export default Header;
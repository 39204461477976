import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_conferencistas.png';
import sergio_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Sergio_Argaelles_espanol.jpg";
import peter_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Peter_Linneman_Ingles.pdf";
import matt_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Matt_Brady_ingles.pdf";
import hamid_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Hamid_Moghadam_espanol.pdf";
import { CaretLeftFilled, CaretRightFilled, CloseOutlined } from "@ant-design/icons";

import ponente1 from "../../assets/images/2024/ponentes/casaduana_PabloMejia.png";
import ponente2 from "../../assets/images/2024/ponentes/casaduana_BrendaAngeles.png";
import ponente3 from "../../assets/images/2024/ponentes/casaduana_JanettAguilar.png";
import ponente4 from "../../assets/images/2024/ponentes/casaduana_OmarChavez.png";
import ponente5 from "../../assets/images/2024/ponentes/casaduana_RobertoOchoa.png";
import ponente6 from "../../assets/images/2024/ponentes/casaduana_FernandoMejia.png";
import ponente7 from "../../assets/images/2024/ponentes/casaduana_FelipePena.png";
import ponente8 from "../../assets/images/2024/ponentes/casaduana_DanielSantos.png";

import Slider from "react-slick";

const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Pablo Mejía",
    empresa: "",
    cargo: "Agente Aduanal",
    img: ponente1,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Brenda Ángeles",
    empresa: "",
    cargo: "Abogada en Comercio Exterior",
    img: ponente2,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Janett Aguilar",
    empresa: "",
    cargo: "Socia en Crowe México",
    img: ponente3,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Omar Chávez",
    empresa: "",
    cargo: "CMO Advisors",
    img: ponente4,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Roberto Ochoa",
    empresa: "",
    cargo: "CMO Advisors",
    img: ponente5,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Fernando Mejía",
    empresa: "",
    cargo: "Director General de CASADUANA",
    img: ponente6,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Felipe de Javier Peña",
    empresa: "",
    cargo: "Presidente del CONAGO",
    img: ponente7,
    url: '',
    semblanza: [<></>],
  },{
    nombre: "Daniel Santos",
    empresa: "",
    cargo: "Dr. en Desarrollo y Seguridad Nacional",
    img: ponente8,
    url: '',
    semblanza: [<></>],
  }
];
const Ponentes = (props) => {
  const ref_carousel = React.createRef();
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
    );
  }

  const settingsPonentes = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      }
    ]
  };

  let ver_ponentes = expositor.map((seccion, index) => {
    return (
      <>
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con color_naranja bold_font">{seccion.nombre}</p>
              <br />
              <p className="empresa_con color_blanco">{seccion.empresa}</p>
              <p className="cargo_con color_blanco">{seccion.cargo}</p>
              <br />
              <p style={{display:'non'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_aqua"
              >
                Semblanza
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
        </>
    );
  });
  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

    let ver_ponentes_fijo = expositor.map((item, index) => {
      const isOdd = (index+1) % 2 !== 0; // Determina si el índice es impar
      return (
        <Col 
          xs={10} 
          md={5} 
          className={isOdd ? 'impar' : 'par'}
          style={isOdd ? { paddingTop: '30px' } : {}}
          key={index} // Agrega una clave única
        >
          <img src={item.img} className="imagen_con" alt="" /><br />
          <p className="nombre_ponente">{item.nombre}</p>
          <p className="cargo_ponente">{item.cargo}</p>
          <br /><br />
        </Col>
      );
    });
  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
        <Col span={24} offset={0}>
          <Row justify="center" align="middle">
            <Col
              span={24}
              offset={0}>
             <p className="titulo_ponentes">Speakers</p><br /><br />
            </Col>
          </Row>
        </Col>
        <Col xs={20} md={18}>
          <Row justify={'space-around'}>
            {ver_ponentes_fijo}
          </Row>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;

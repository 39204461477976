import React from 'react';
import { Row, Col } from 'antd';
import '../../assets/css/patrocinadores.css';

import patro1 from '../../assets/images/2024/organizadores/Casaduana.png';
import patro2 from '../../assets/images/2024/organizadores/Wythe.png';
import patro3 from '../../assets/images/2024/organizadores/Madison.png';
import patro4 from '../../assets/images/2024/organizadores/MadisonTransport.png';
import patro5 from '../../assets/images/2024/organizadores/MejiaSucesores.png';
import patro6 from '../../assets/images/2024/organizadores/Transpiramide.png';

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_patrocinadores.png';


let logos = [
    { tipo: 1, img_src: patro1, nombre: '' },
    { tipo: 1, img_src: patro2, nombre: '' },
    { tipo: 1, img_src: patro3, nombre: '' },
    { tipo: 1, img_src: patro4, nombre: '' },
    { tipo: 1, img_src: patro5, nombre: '' },
    { tipo: 1, img_src: patro6, nombre: '' },
]


const CarouselPatrocinadores = (params) => {


    const plata = logos.filter(item =>{
      if(item.tipo === 1){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:8, offset:0}} md={{span:7}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'70%'}}  />
          <br /><br />
          </Col>
        )
    });
    const oro = logos.filter(item =>{
      if(item.tipo === 2){
      return true}
      else{
      return false}
    }).map((logo,index) => {
        return(
          <Col xs={{span:8, offset:0}}md={{span:7}}>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'80%'}}  />
          </Col>
        )
    });

    return (
      <>
      <Row justify="center" className='' style={{padding:'3% 0%'}}>
        <Col xs={24} md={20}>
          <br />
          <p className='titulo_patrocinadores'>Organizadores</p><br />
          <br />
        </Col>
        <Col xs={24} md={24}>
          <Row justify="center" align="">
            {plata}
          </Row>
        </Col>
        
      </Row>
      </>
    );

};

export default CarouselPatrocinadores;
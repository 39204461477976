import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import RecuperarContrasena from "../sesion/RecuperarContrasena";
import Login from "../registro/Login";
import Error from "../auth/Error";
import Home from "../evento/Home";
import Registro from "../registro/Registro";
import RegistroCerrado from "../registro/RegistroCerrado";
import Webinars from "../conferencias/Webinars";
import EstadisticaGrafica from "../conferencias/EstadisticaGrafica";

import Desarrolladores from "../membresia/Desarrolladores";
import Networking from "../networking/Networking";

import Demo from "../en-vivo/Demo";

import { AuthContext } from "../../utils/AuthContext";
import Admin from "../admin/Admin";
import VisualizarPreguntas from "../admin/VisualizarPreguntas";
import PopUpPregunta from "../conferencias/PopUpPregunta";
import CerrarSesion from "../sesion/CerrarSesion";
import Header from "./Header";
import Footer from "./Footer";

import "../../assets/css/general.css";
import Costos from "../costos/Costos";

export const Master = () => {
  const location = useLocation();
  const [idioma, setIdioma] = useState("es");

  const secciones_auth = [
    //{componente:<Registro tipo_registro="presencial" />, url:'/registro/presencial'},
     /*{
       componente: <Registro tipo_registro="virtual" idioma={idioma} />,
       url: "/registro",
     },*/
    {
      componente: <RegistroCerrado tipo_registro="virtual" idioma={idioma} />,
      url: "/registro",
    },
    { componente: <Demo idioma={idioma} />, url: "/transmision" },
    { componente: <Webinars idioma={idioma} />, url: "/" },
    //{ componente: <Registro idioma={idioma} />, url: "/" },
    {componente:Error, utl:'/*'}
  ];

  const secciones = [
    { componente: Admin, url: "/admin-preguntas" },
    { componente: VisualizarPreguntas, url: "/visualizar-preguntas" },
    { componente: EstadisticaGrafica, url: "/estadisticas-graficas" },
    { componente: CerrarSesion, url: "/cerrar-sesion" },
  ];

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    if (location.hash === "") {
      scroller.scrollTo("header");
    } else {
      scroller.scrollTo(location.hash);
    }
  });

  useEffect(() => {
    sleep(1000).then(() => {
      if (location.hash === "") {
        scroller.scrollTo("header");
      } else {
        scroller.scrollTo(location.hash);
      }
    });
  }, []);

  let visualizar_secciones_auth = secciones_auth.map((seccion) => {
    if (
      seccion.url === "/registro" ||
      seccion.url === "/registro"
    ) {
      return (
        <Route path={seccion.url} exact>
          {seccion.componente}
        </Route>
      );
    }
    return (
      <Route
        path={seccion.url}
        exact
        render={(props) => {
          return seccion.componente;
        }}
      />
    );
  });

  let visualizar_secciones = secciones.map((seccion) => {
    return (
      <Route
        path={seccion.url}
        exact
        component={seccion.componente}
        idioma={idioma}
      />
    );
  });

  return (
    <AuthContext>
      <Element name="header"></Element>
      <Header />
      <Switch>
        {visualizar_secciones}
        {visualizar_secciones_auth}

        <Route path="/*" exact component={Error} idioma={idioma} />
      </Switch>
      <Footer />
    </AuthContext>
  );
};

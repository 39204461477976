import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/agenda.css";

import titulo from '../../assets/images/reunion_anual/TITULOS/titulo_agenda.png';

import clinica_golf from '../../assets/documentos/Clinica_de_golf.pdf';
import info_pdf from '../../assets/documentos/Torneo_de_golf.pdf';
import agotado from '../../assets/images/reunion_anual/golf_cupo-agotado.jpg';

import { CloseOutlined } from "@ant-design/icons";


const Agenda = (props) => {
  const [dia, setDia] = useState(1);
  const [ver_order, setVerOrder] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const conferencia = [
    {
      dia: 1,
      hora: "08:00 h",
      titulo:[<><span className="">Registro</span></>],
      desc: null,
      back: "",
    }, {
      dia: 1,
      hora: "09:00 h",
      titulo:[<><span className="">Inauguración y bienvenida - Director General y Agentes Aduanales de CASADUANA</span></>],
      desc: null,
      back: "",
    }, {
      dia: 1,
      hora: "09:30 h",
      titulo: [<><span className="">Panel 1 - "Digitalización del SAT y las Aduanas: Perspectiva del Sector Privado"</span></>],
      desc: [<><br /><i>Panelistas:</i> <br />
      Janett Aguilar- Socia en Crowe México<br />
        Felipe de Javier Peña - Presidente del CONAGO<br /><br />
        <i>Moderador:</i> <br />
        Brenda Angeles - Abogada de CASADUANA
        </>],
      back: "",
    }, {
      dia: 1,
      hora: "10:30 h",
      titulo: [<><span className="">Panel 2 - "¿Qué viene para las IMMEX certificadas?"</span></>],
      desc: [<><br /><i>Panelistas:</i> <br />
      Omar Chávez y Roberto Ochoa - Socios de CMO Advisors<br /><br />
        <i>Moderador:</i> <br />
        Brenda Angeles - Abogada de CASADUANA<br />
        Pablo Mejía - Agente Aduanal
      </>],
      back: "",
    }, {
      dia: 1,
      hora: "11:15 h",
      titulo: [<><span className="">Receso - Coffee Break</span></>],
      desc: null,
      back: "",
    },{
      dia: 1,
      hora: "11:30 h",
      titulo: [<><span className="">Panel 3 - "Retos y desafíos para el comercio exterior mexicano en 2025"</span></>],
      desc: [<><br /> <i>Panelista:</i> <br />
      Daniel Santos - Dr. en Desarrollo y Seguridad Nacional<br /><br />
        <i>Moderador:</i> <br />
        Brenda Angeles - Abogada de CASADUANA
        
      </>],
      back: "",
    },{
      dia: 1,
      hora: "12:00 h",
      titulo: [<><span className="">Panel 4 - "Modificaciones a las RGCE, nearshoring e impacto del entorno internacional"</span></>],
      desc: [<><br /> <i>Panelista:</i> <br />
      Brenda Angeles - Abogada de CASADUANA<br />
      Pablo Mejía - Agente Aduanal<br />
      Fernando Mejía - Director General

      </>],
      back: "",
    },{
      dia: 1,
      hora: "12:40 h",
      titulo: [<><span className="">Dinámica con asistentes y entrega de premios</span></>],
      desc: null,
      back: "",
    },{
      dia: 1,
      hora: "12:50 h",
      titulo: [<><span className="">Clausura - Director General y Agentes Aduanales de CASADUANA</span></>],
      desc: null,
      back: "",
    },
  ];
 
  let visualizar_agenda = conferencia
    .filter((item) => {
      if (item.dia === dia) {
        return true;
      } else {
        return false;
      }
    })
    .map((da, index) => {
      return (
        <Row
          
          align="top"
          style={{ padding: "15px 0px" }}
          className={da.back}
        >
          <Col xs={{span:22, offset:1}} md={{span:4, offset:0}}>
            <p className="hora_agenda color_verde">{da.hora}</p>
          </Col>
          <Col xs={{span:22, offset:1}} md={{span:17, offset:3}}>
          <p className="desc_agenda color_azul">{da.titulo}</p>
          <p className="texto_agenda">{da.desc}</p>
          </Col>
        </Row>
      );
    });


  return (
    <Row className="back_titulo_agenda">
      <Col span={18} offset={3}>
        <Row justify="center" align="middle" style={{ position: "" }}>
          <Col
            span={24}
            offset={0}
            style={{ position: "", width: "100%", paddingTop:'3%' }}
          >
            <p className="titulo_agenda">Programa del evento</p>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
        <br />
        </Col>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }}>
        <Row justify="start">
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
          >
            <div className="back_azul_claro espacio_dias">
            <p className="color_blanco tex_agenda_dia">
              <strong>24</strong><br />
              <span className="fecha_text_agenda">enero, 2025</span>
            </p>
            </div>
          </Col>
         
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 0 }} className="sombra_agenda back_blanco">
        
        {visualizar_agenda}

      
      </Col>
          
        </Row><br />
      </Col>
      
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
        
      <br />
        <br /><br /><br />
        </Col>
    </Row>
  );
};
export default Agenda;

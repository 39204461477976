import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import ICalendarLink from "react-icalendar-link";
import { Element } from "react-scroll";

import Agenda from "./Agenda";
import Ponentes from "./Ponentes";

import "../../assets/css/webinars.css";

import banner from "../../assets/images/2024/casaduana_bannerHome.jpg";

import pdf_patrocinadores from '../../assets/documentos/PATROCINADORES.pdf';

import btn_ayuda from "../../assets/images/tercera_reunion/btn_whastapp.png";
import btn_ayuda_en from "../../assets/images/tercera_reunion/btn_whastapp.png";

import btn_registro from "../../assets/images/2024/Logos_Botones_Iconos/casaduana_Registrate.svg";

import fecha from "../../assets/images/2024/Logos_Botones_Iconos/casaduana_calendario.svg";
import lugar from "../../assets/images/2024/Logos_Botones_Iconos/casaduana_gps.svg";

import aparta from "../../assets/images/2024/Logos_Botones_Iconos/casaduana_ApartaLugar.svg";

import img_1 from "../../assets/images/2024/casaduana_objetivo01.jpg";
import img_2 from "../../assets/images/2024/casaduana_objetivo02.jpg";
import img_3 from "../../assets/images/2024/casaduana_objetivo03.jpg";


import img_cuadro_1 from "../../assets/images/2024/casaduana_gratis.png";
import img_cuadro_2 from "../../assets/images/2024/casaduana_networking.png";
import img_cuadro_3 from "../../assets/images/2024/casaduana_tecnologia.png";

import "../../assets/css/conferencia.css";
import Contador from "../evento/Contador";
import Membresia from "../membresia/Membresia";
import Hospedaje from "../membresia/Hospedaje";
import CarouselPatrocinadores from "../evento/CarouselPatrocinadores";
import Registro from "../registro/Registro";

const Webinars = (props) => {
  const { idioma } = props;
  

  return (
    <>
      <Row>
        <Col span={24}>
          <Row style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={banner} style={{ width: "100%" }} />
            </Col>
            <Col
              span={24}
              offset={0}
              className="contenedor_banner_menu"
              style={{ position: "absolute", width: "100%" }}
            >
              <Row justify="center">
                <Col xs={0} md={24}>
                <br /><br /><br />
                </Col>
                <Col xs={20} md={18}>
                  <p className="color_blanco fecha_banner_home"><img src={fecha} style={{width:'25px'}} />  <span>24 de enero 2025 • 8:00 h</span></p>
                  
                  <p className="titulo_banner_home">
                    <span className="color_verde titulo_banner_home_uno">RETOS Y DESAFÍOS</span><br />
                    <span className="color_blanco titulo_banner_home_dos">PARA EL COMERCIO EXTERIOR MEXICANO</span><br />
                    <span className="color_blanco titulo_banner_home_tres">EN 2025</span>
                    </p><br />
                    <p className="titulo_banner_home">
                    <span className="color_blanco sincosto_banner">¡EVENTO SIN COSTO!</span>
                  </p>
                </Col>
                <Col xs={0} md={14}>
                  <br />
                </Col>
                <Col xs={0} md={18} style={{textAlign:'left'}}>
                  <Link to="#registro" className="">
                    <img src={btn_registro} style={{width:'180px'}} />
                  </Link>
                  <Link to="#registro" className="">
                    <img src={lugar} style={{width:'60px'}} /><span className="color_blanco fecha_banner_home">Hotel Marquis Reforma • CDMX</span>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Element name="#acerca-de"></Element>
      <Row align={'middle'} justify={'center'} className="contenedor_acercade">
        <Col xs={20} md={18}>
          <Row>
            <Col xs={{span:24}} md={{span:11}}><br /><br />
              <p className="acerca_home_titulo">Honrando el pasado, construyendo tu futuro</p><br />
              <p className="acerca_home_objetivo color_verde">Objetivo</p>
              <p className="acerca_home_texto">
              Conoce las <strong>últimas modificaciones a las RGCE</strong> y demás normativa en materia de comercio exterior que impactarán en la operación aduanera. Así mismo, todas las actualizaciones entorno al fenómeno del <strong>nearshoring y el próximo presidente de los Estados Unidos</strong> y sus repercusiones en las operaciones de comercio exterior y que viene para este 2025 en el desarrollo de las cadenas de suministro.
              </p><br />
              <p style={{textAlign:'left'}}>
                <Link to="#registro"><img src={aparta} style={{width:'180px'}} /></Link>
              </p>
            </Col>
            <Col xs={{span:24, offset:0}} md={{span:10, offset:2}}>
              <Row align={'middle'}>
                <Col xs={{span:11}}>
                  <img src={img_1} className="img_100" />
                </Col>
                <Col xs={{span:11, offset:1}}>
                  <img src={img_2} className="img_100" />
                  <br /><br />
                  <img src={img_3} className="img_100" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Element name="#beneficios"></Element>
      <Row align={''} justify={'center'} className="paddin_contenedor">
        <Col xs={6}>
          <img src={img_cuadro_1} style={{width:'90%',paddingTop:'30px'}} />
        </Col>
        <Col xs={6}>
          <img src={img_cuadro_2} style={{width:'90%',paddingTop:'0px'}} />
        </Col>
        <Col xs={6}>
          <img src={img_cuadro_3} style={{width:'90%',paddingTop:'30px'}} />
        </Col>
      </Row>
      <Row className="contenedor_conferencias_" style={{backgroundColor:'#f6fafc'}}>
        <Col id="programa" span={24} className="back_agenda__">
          <Element name="#programa"></Element>
          <Agenda idioma={idioma} />
        </Col>
        <Col span={24}>
        <Element name="#registro"></Element>
          <Registro idioma={idioma} />
        </Col>
        <Col span={24}>
          <Element name="#speakers"></Element>
          <Ponentes />
        </Col>
        <Col span={24}>

        <Element name="#organizadores"></Element>
        <CarouselPatrocinadores />
        </Col>
        <Col span={0} className="back_azul paddin_contenedor">

          <Row justify="center" align="middle">
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }}>
              <p className="bold_font costo_patrocinio_hoome color_azul_claro_2">
              ¡Forma parte del futuro industrial de México!<br />
              <span className="color_blanco"><strong>Para más información sobre patrocinios</strong></span>
              </p>
            </Col>
            <Col xs={24} md={0}>
              <br />
            </Col>
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 6, offset: 0 }}>
              <Link className="btn_patrocinio_hoome" to={pdf_patrocinadores} target="_blank">
              <strong className="bold_font">DA CLIC AQUÍ</strong>
              </Link>
            </Col>
            <Col xs={24} md={0}>
              <br />
            </Col>
          </Row>
        </Col>

       
        
        
      </Row>
    </>
  );
};

export default Webinars;

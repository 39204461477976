import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';


import usuario_img from '../../assets/images/evento/1_REGISTRO/icono-usuario.png';
import apellido_img from '../../assets/images/evento/1_REGISTRO/icono-password.png';
import img_entrar_recepcion from '../../assets/images/registro/registrate_masterclass_blanco.png';
import pago_paypal from '../../assets/images/registro/LOGO_paypal.png';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const RegistroCompleto = (props) => {

    const { correo, password, tipo_registro, nombre = '', idioma } = props;

    /*useEffect(() => {
        scroller.scrollTo('header');
    }, []);*/

    return (
        <Row align="middle">
            <Col span={22} offset={2}>


                {/*tipo_registro === 'presencial' ?*/}
                
                <br /><br /><br /><br />
                <p className="textos_titulos_secciones" style={{ textAlign: 'left' }}>¡Gracias por su registro!</p>
                
                    <p style={{ textAlign: 'left' }}>
                        <span className="texto-breve color_amarillo"><strong>{nombre}</strong></span>
                        <br /><br />
                        <span className="texto-breve color_blanco ">En breve recibirá un correo de confirmación a la siguiente dirección: </span><br />

                        <span className="correo-azul color_verde "> <strong>{correo}</strong> </span><br /> <br />
                        <span className="leyenda color_gris_claro ">Si no encuentra el mail de confirmación en su bandeja de entrada, es posible que esté en la <br />carpeta de SPAM / NO DESEADOS.</span> <br /> <br />
                    </p>
                    </Col>
                    
                <Col span={22} offset={1}>
                
                <br /><br />
                {/*<span className='leyenda'>Para cualquier información adicional, favor de dirigirse a Cecilia Toribio, Coordinadora de Eventos <a href="mailto:ctoribio@ampip.org.mx">ctoribio@ampip.org.mx</a></span>*/}
            </Col>
        </Row>
    );
}

export default RegistroCompleto;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import { Element } from 'react-scroll';

import rede_ins from '../../assets/images/2024/Logos_Botones_Iconos/instagram.svg';
import rede_fb from '../../assets/images/2024/Logos_Botones_Iconos/facebook.svg';
import rede_yt from '../../assets/images/2024/Logos_Botones_Iconos/youtube.svg';
import rede_link from '../../assets/images/2024/Logos_Botones_Iconos/linkedin.svg';
import logo from '../../assets/images/2024/Logos_Botones_Iconos/casaduana_LogoFooter.svg';
import logo_wdp from '../../assets/images/2024/Logos_Botones_Iconos/whatsapp.svg';

const Footer = () => {
  let location = useLocation();

  return (
    <>
      <Row justify="center" align="middle" style={{ backgroundColor: '#134472', padding: '1% 0%' }}>

        <Col xs={{ span: 24 }}>
          <Row justify={'center'} align="middle" >
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 4, offset: 0 }} style={{ padding: '5px 10px', textAlign: 'left' }}>
              <a href="https://casaduana.com/" target="_blank" className="link_sitio">
                <img src={logo} style={{ width: '150px' }} />
              </a>
            </Col>
            <Col xs={{ span: 7, offset: 0 }} md={{ span: 0, offset: 0 }} style={{ padding: '5px 10px', textAlign: 'left' }}>
              <a href="https://casaduana.com/" target="_blank" className="link_sitio monserrat">
                <img src={logo} style={{ width: '100px' }} />
              </a>
            </Col>
            <Col xs={24} md={0}>
            </Col>
            <Col xs={{ span: 10, offset: 0 }} md={{ span: 4, offset: 7 }}>
              <p className='color_blanco texto_footer'>Dudas y aclaraciones</p>
            </Col>
            <Col xs={{ span: 10, offset: 0 }} md={{ span: 4, offset: 1 }} style={{textAlign:''}}>
            <img src={logo_wdp} className='wsp_footer' /> <a href='https://api.whatsapp.com/send?phone=528671262082' className='link_footer color_blanco'>+52 867 126 2082</a>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }}>
          <Row justify={'center'}>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 15, offset: 0 }}>

            </Col>
            <Col xs={{ offset: 1, span: 4 }} md={{ offset: 0, span: 1 }}>
              <a href="https://www.facebook.com/casaduana.mx/" target="_blank"><img className="redes_2" src={rede_fb} /></a>
            </Col>
            <Col xs={{ offset: 0, span: 4 }} md={{ offset: 0, span: 1 }}>
              <a href="https://www.instagram.com/casaduana/?hl=es" target="_blank"><img className="redes_2" src={rede_ins} /></a>
            </Col>
            <Col xs={{ offset: 0, span: 4 }} md={{ offset: 0, span: 1 }} >
              <a href="https://www.linkedin.com/company/mejia-y-sucesores-s-c/" target="_blank"><img className="redes_2" src={rede_link} /></a>
            </Col>
            <Col xs={{ offset: 0, span: 4 }} md={{ offset: 0, span: 1 }} >
              <a href="https://www.youtube.com/channel/UCOPkJY7NRSxK0w9XDQp2GBA?app=desktop" target="_blank"><img className="redes_2" src={rede_yt} /></a>
            </Col>
          </Row>
        </Col>
        <Col
            xs={{ span: 20, offset: 0 }}
            md={{ span: 20, offset: 0 }}
          >
            <span className='leyenda'>© Todos los derechos reservados • 2025 • Diseño y Programación: <a style={{ color: 'white' }} href="https://www.thethree.com.mx/">thethree.com.mx</a></span>
          </Col>
      </Row>
    </>
  );
}

export default Footer;